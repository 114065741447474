import React from 'react';
import { ERROR_CODE_TO_TRANSLATION_KEY } from '../i18n/enumMappings';
import { CustomFormattedMessage, TranslationsKeys } from '../i18n/i18n';
import { HighlightPanel } from './HighlightPanel';
import { SerializedError } from '@reduxjs/toolkit';
import { BackendErrors } from '../models';
import { useLocale } from '../i18n/locale';

type ErrorMessagePanelProps = {
  errorCodes: string[] | SerializedError;
};

const isExactError = (error: string): error is BackendErrors => {
  return error in BackendErrors;
};

const DEFAULT_ERROR_MESSAGE: TranslationsKeys = 'common.defaultErrorMessage';

const isStringArray = (
  errorCodes: string[] | SerializedError,
): errorCodes is string[] => (errorCodes as string[]).length !== undefined;

const determineErrorMessage = (
  errorCodes: string[] | SerializedError,
): TranslationsKeys => {
  if (!isStringArray(errorCodes)) {
    return DEFAULT_ERROR_MESSAGE;
  }

  const exactError = errorCodes.find(isExactError);

  if (exactError) {
    return ERROR_CODE_TO_TRANSLATION_KEY[exactError];
  }

  return DEFAULT_ERROR_MESSAGE;
};

export const ErrorMessagePanel: React.FC<ErrorMessagePanelProps> = ({
  errorCodes,
}) => {
  const { locale } = useLocale();
  const translationKey = determineErrorMessage(errorCodes);

  const renderRioSupportLink = (linkText: string[]) => (
    <a
      className="text-decoration-underline"
      href={`https://contact-form.rio.cloud/contact?opener=https%3A%2F%2Fuser-menu.iam.rio.cloud%2F&locale=${locale}`}
      target="_blank"
      rel="noreferrer"
    >
      {linkText}
    </a>
  );

  return (
    <div data-testid={'onboarding-dialog-error-message'}>
      <HighlightPanel type={'danger'}>
        <CustomFormattedMessage
          id={translationKey}
          values={{ link: renderRioSupportLink }}
        />
      </HighlightPanel>
    </div>
  );
};
