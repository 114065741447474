import { configureStore } from '@reduxjs/toolkit';
import { loginSlice, User } from '../login/loginSlice';
import { localeSlice } from '../i18n/locale';
import { onboardingUiSlice } from '../pages/onboarding/state/onboardingUiSlice';
import {
  vehicleMiddleware,
  vehicleReducer,
  vehicleReducerPath,
} from './vehicles/vehiclesApiTemplateSlice';
import {
  transactionsMiddleware,
  transactionsReducer,
  transactionsReducerPath,
} from './transactions/transactionsApiTemplateSlice';
import { transactionFilterSlice } from './transactions/transactionFilterSlice';
import {
  vehicleGroupsMiddleware,
  vehicleGroupsReducer,
  vehicleGroupsReducerPath,
} from './vehicleGroups/vehicleGroupsSlice';
import { transactionsUiSlice } from '../pages/transactions/state/transactionsUiSlice';
import { supportDialogSlice } from '../pages/state/supportDialogSlice';
import { transactionUpdateSlice } from './transactions/transactionUpdateSlice';
import {
  bookingsMiddleware,
  bookingsReducer,
  bookingsReducerPath,
} from './bookings/bookingsApiTemplateSlice';
import { bookingsFilterSlice } from './bookings/bookingsFilterSlice';
import { bookingsUiSlice } from '../pages/bookings/state/bookingsUiSlice';
import {
  locationsMiddleware,
  locationsReducer,
  locationsReducerPath,
} from './locations/locationsApiTemplateSlice';

export const createStore = (user: User) => {
  return configureStore({
    reducer: {
      locale: localeSlice.reducer,
      login: loginSlice(user).reducer,
      globalUiState: supportDialogSlice.reducer,
      onboardingUiState: onboardingUiSlice.reducer,
      transactionsUiState: transactionsUiSlice.reducer,
      transactionFilter: transactionFilterSlice.reducer,
      transactionUpdate: transactionUpdateSlice.reducer,
      bookingsUiState: bookingsUiSlice.reducer,
      bookingsFilter: bookingsFilterSlice.reducer,
      [vehicleReducerPath]: vehicleReducer,
      [vehicleGroupsReducerPath]: vehicleGroupsReducer,
      [transactionsReducerPath]: transactionsReducer,
      [bookingsReducerPath]: bookingsReducer,
      [locationsReducerPath]: locationsReducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(
        vehicleMiddleware,
        transactionsMiddleware,
        vehicleGroupsMiddleware,
        bookingsMiddleware,
        locationsMiddleware,
      ),
  });
};

type Store = ReturnType<typeof createStore>;
export type RootState = ReturnType<Store['getState']>;
export type AppDispatch = Store['dispatch'];
