import React, { useEffect, useMemo, useState } from 'react';
import { SortDirection, VehicleWithGroups } from '../../../../models';
import {
  CustomFormattedMessage,
  TranslationsKeys,
  useCustomIntl,
} from '../../../../i18n/i18n';
import {
  SERVICE_TO_TRANSLATION_KEY,
  VEHICLE_TYPE_TO_TRANSLATION_KEY,
} from '../../../../i18n/enumMappings';
import { useAppDispatch, useAppSelector } from '../../../../state/hooks';
import {
  getSelectedVehicle,
  openSidebar,
  setSelectedVehicle,
} from '../../state/onboardingUiSlice';
import { VehicleTableToolbar } from './VehicleTableToolbar';
import { useVehicles } from '../../../../state/vehicles/useVehicles';
import { NoDataPlaceholder } from '../../../../components/NoDataPlaceholder';
import { ServiceRepresentation } from '../../../../components/dataRepresentation/ServiceRepresentation';
import { RowData, Table } from '../../../../components/table/Table';
import { sortByProperty } from '@rio-cloud/rio-uikit/lib/es/SortUtils';
import {
  RowColoring,
  TableColumn,
} from '../../../../components/table/TableRow';
import {
  PaymentMethodState,
  ServiceType,
  VehicleType,
} from '../../../../codegen/vehicles';
import { VehicleRepresentation } from '../../../../components/dataRepresentation/VehicleRepresentation';
import { VehicleGroupsRepresentation } from '../../../../components/dataRepresentation/VehicleGroupsRepresentation';
import { useLocale } from '../../../../i18n/locale';

type VehicleRow = {
  name: string;
  vin: string;
  type: VehicleType;
  brand: string;
  groups: string[];
  services: ServiceType[];
};

type SortingState = {
  sortBy?: keyof VehicleRow;
  sortDirection: SortDirection;
};

export const VehicleTable: React.FC = () => {
  const dispatch = useAppDispatch();
  const intl = useCustomIntl();
  const { locale } = useLocale();
  const { vehicles, error, groups: vehicleGroups } = useVehicles();
  const selectedVehicle = useAppSelector(getSelectedVehicle);
  const [highlightedRow, setHighlightedRow] = useState<string | undefined>();

  useEffect(() => {
    setHighlightedRow(selectedVehicle?.id);
  }, [selectedVehicle]);

  const [sorting, setSorting] = useState<SortingState>({
    sortDirection: SortDirection.ASCENDING,
  });

  const getUniqueServiceTypes = (v: VehicleWithGroups) => {
    return [
      ...new Set(
        v.serviceActivations.filter((s) => s.enabled).map((s) => s.serviceType),
      ),
    ];
  };

  const handleVehicleClicked = (id: string) => {
    const vehicle = vehicles.find((v) => v.id === id);
    if (vehicle) {
      dispatch(setSelectedVehicle(vehicle));
      dispatch(openSidebar());
    }
  };

  const handleSearchBarValueChanged = (value: string) => {
    setFiltering({ ...filtering, searchBarValue: value });
  };

  const handleGroupsFilterValueChanged = (selected: string[]) => {
    const filter = filtering.groupFilterOptions;
    filter.forEach((f) => {
      f.selected = selected.includes(f.id);
    });
    setFiltering({ ...filtering, groupFilterOptions: filter });
  };

  const handleSortChanged = (
    sortBy: keyof VehicleRow,
    sortDirection: SortDirection,
  ) => {
    setSorting((prevSorting) => ({
      ...prevSorting,
      sortBy,
      sortDirection,
    }));
  };

  const initialFilteringState = {
    searchBarValue: '',
    groupFilterOptions: vehicleGroups.map((g) => {
      return {
        id: g.name,
        label: g.name,
        selected: false,
      };
    }),
  };

  const [filtering, setFiltering] = useState(initialFilteringState);

  const filterBySearchBarValue = (vehicle: VehicleWithGroups) => {
    const filterOn = [
      vehicle.vin,
      vehicle.name,
      intl.formatMessage({ id: VEHICLE_TYPE_TO_TRANSLATION_KEY[vehicle.type] }),
      vehicle.brand,
      ...vehicle.groups.map((g) => g.name),
      ...getUniqueServiceTypes(vehicle).map((service) =>
        intl.formatMessage({ id: SERVICE_TO_TRANSLATION_KEY[service] }),
      ),
    ];
    return filterOn.some((value) =>
      new RegExp(filtering.searchBarValue, 'i').test(value),
    );
  };

  const filterBySelectedGroups = (vehicle: VehicleWithGroups) => {
    const groups = filtering.groupFilterOptions
      .filter((f) => f.selected)
      .map((g) => g.id);

    return (
      !groups.length || vehicle.groups.some((g) => groups.includes(g.name))
    );
  };

  const filteredVehicles = useMemo(() => {
    return vehicles
      .filter(filterBySearchBarValue)
      .filter(filterBySelectedGroups);
  }, [filtering, vehicles, locale]);

  const renderVehicleName = (vehicle: VehicleRow) => {
    return (
      <VehicleRepresentation
        vehicleType={vehicle.type}
        vehicleName={vehicle.name}
      />
    );
  };

  const renderVehicleType = (vehicle: VehicleRow) => {
    return (
      <CustomFormattedMessage
        id={VEHICLE_TYPE_TO_TRANSLATION_KEY[vehicle.type]}
      />
    );
  };

  const renderVehicleGroups = (vehicle: VehicleRow) => {
    return <VehicleGroupsRepresentation vehicleGroups={vehicle.groups} />;
  };

  const renderVehicleServices = (vehicle: VehicleRow) => {
    return (
      <>
        {vehicle.services.map((service, index) => {
          return (
            <div key={index}>
              <ServiceRepresentation serviceType={service} />
            </div>
          );
        })}
      </>
    );
  };

  const getLabel = (id: TranslationsKeys) => {
    return intl.formatMessage({
      id,
    });
  };

  const columns: TableColumn<VehicleRow>[] = [
    {
      id: 'name',
      label: getLabel('onboarding.vehicleTable.header.name'),
      renderer: renderVehicleName,
    },
    {
      id: 'vin',
      label: getLabel('onboarding.vehicleTable.header.vin'),
      renderer: (vehicle: VehicleRow) => <>{vehicle.vin}</>,
    },
    {
      id: 'type',
      label: getLabel('onboarding.vehicleTable.header.type'),
      renderer: renderVehicleType,
    },
    {
      id: 'brand',
      label: getLabel('onboarding.vehicleTable.header.brand'),
      renderer: (vehicle: VehicleRow) => <>{vehicle.brand}</>,
    },
    {
      id: 'groups',
      label: getLabel('onboarding.vehicleTable.header.groups'),
      renderer: renderVehicleGroups,
    },
    {
      id: 'services',
      label: getLabel('onboarding.vehicleTable.header.services'),
      renderer: renderVehicleServices,
    },
  ];

  const mapVehicleToRow = (v: VehicleWithGroups): RowData<VehicleRow> => {
    const hasFailedPaymentMethods = v.paymentMethods.some(
      (method) => method.state === PaymentMethodState.OnboardingFailure,
    );
    return {
      key: v.id,
      name: v.name,
      vin: v.vin,
      type: v.type,
      brand: v.brand,
      groups: v.groups.map((g) => g.name),
      services: getUniqueServiceTypes(v),
      coloring: hasFailedPaymentMethods ? RowColoring.DANGER : undefined,
      enabled: v.enabled,
    };
  };

  const rowData = filteredVehicles.map((v) => mapVehicleToRow(v));

  const sortedRowData = sorting.sortBy
    ? sortByProperty(rowData, sorting.sortBy, sorting.sortDirection)
    : rowData;

  if (error) {
    return <NoDataPlaceholder />;
  }

  return (
    <div className="display-flex flex-column height-100pct">
      <VehicleTableToolbar
        groupFilterOptions={filtering.groupFilterOptions}
        onGroupFilterChanged={handleGroupsFilterValueChanged}
        searchBarValue={filtering.searchBarValue}
        onSearchBarValueChanged={handleSearchBarValueChanged}
      />
      <div
        className="table-responsive overflow-y-auto "
        data-testid="onboarding-body-vehicle-table"
      >
        <Table
          sorting={{
            sortBy: sorting.sortBy,
            sortDirection: sorting.sortDirection,
            onSortChanged: handleSortChanged,
          }}
          columns={columns}
          rowData={sortedRowData}
          onRowClicked={(key) => {
            setHighlightedRow(key);
            handleVehicleClicked(key);
          }}
          highlightedRow={highlightedRow}
        />
      </div>
    </div>
  );
};
