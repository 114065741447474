import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useAppDispatch, useAppSelector } from '../state/hooks';
import type { RootState } from '../state/store';
import { DEFAULT_LOCALE } from './i18n';

type LocaleState = {
  locale: string;
};

const initialState: LocaleState = {
  locale: DEFAULT_LOCALE,
};

export type UserMenuLanguageChangedEvent = {
  detail: {
    language: string;
  };
} & Event;

export const localeSlice = createSlice({
  name: 'locale',
  initialState,
  reducers: {
    setLocale: (state, action: PayloadAction<string>) => {
      state.locale = action.payload;
    },
  },
});

const getLocale = (state: RootState) => state.locale.locale;

export const useLocale = () => {
  const locale = useAppSelector(getLocale);
  const dispatch = useAppDispatch();
  const setLocale = (newlocale: string) => {
    const html = document.querySelector('html');

    if (html && newlocale && html.getAttribute('lang') !== newlocale) {
      html.setAttribute('lang', newlocale);
    }

    dispatch(localeSlice.actions.setLocale(newlocale));
  };

  return {
    locale,
    setLocale,
  };
};
